<template lang="pug">
  BaseSelect(v-model="data" :list="list" @input="handleChange" :clearable="clearable")
</template>

<script>
import BaseSelect from './BaseSelect.vue';
import { generateTypes } from '@/utils/const';

export default {
  components: {
    BaseSelect,
  },
  props: {
    value: {
      type: [String, Number],
    },
    clearable: {
      type: Boolean,
      value: true,
    },
  },
  data() {
    const list = generateTypes.map((e) => ({
      ...e,
      value: e.text,
    }));
    // list.unshift({ label: '全部', value: 10 });

    return {
      list,
      data: this.value,
    };
  },
  watch: {
    value() {
      this.data = this.value;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e);
    },
  },
};
</script>
