<template lang="pug">
  BaseSelect(v-model="data" :list="list" @input="handleChange" clearable)
</template>

<script>
import BaseSelect from './BaseSelect.vue';
import { bindStatus } from '@/utils/const';

export default {
  components: {
    BaseSelect,
  },
  props: {
    value: { value: undefined },
    clearable: {
      type: Boolean,
      value: true,
    },
  },
  data() {
    const list = bindStatus;
    // list.unshift({ label: '全部', value: 10 });

    return {
      list,
      data: this.value,
    };
  },
  watch: {
    value() {
      this.data = this.value;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e);
    },
  },
};
</script>
